import http from './http';
import { oioHeader } from '@/utils';
import { USER_URL } from '@/constants/config';

const baseUrl = USER_URL;

class AuthService {
  /**
   * Get app token
   *
   * @param {Object} [param] object optional
   * @param {string} [param.client_id] client id optional
   * @param {string} [param.client_secret] client secret optional
   * @param {string} [param.grant_type] grant type optional
   */
  async token({ client_id = '45', client_secret = '45', grant_type = 'client_credentials' } = {}) {
    const body = { client_id, client_secret, grant_type };
    try {
      const { data: result } = await http.post(`${baseUrl}/token`, body, {});
      return result;
    } catch (e) {
      throw new Error(e);
    }
  }

  /**
   * Authorize
   *
   * @param {Object} param object
   * @param {string} poram.email email
   * @param {string} poram.password password
   * @param {string} [poram.grant_type=password] grant type (optiona)
   */
  async authorize({ email, password, grant_type = 'password' } = {}) {
    const body = { email, password, grant_type };
    try {
      const { data: result } = await http.post(`${baseUrl}/oauth/authorize`, body, {
        headers: { ...oioHeader() }
      });
      return result;
    } catch (e) {
      throw new Error(e);
    }
  }
}
export default new AuthService();
