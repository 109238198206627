<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <img src="@/assets/images/logo/dio@2x.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <!-- <h4 class="mb-4">Giriş</h4> -->
                  <p v-if="!error">Hoşgeldiniz, lütfen hesabınıza giriş yapınız.</p>
                  <p v-if="error" id="error">Email ya da şifre hatalı, lütfen tekrar deneyiniz.</p>
                </div>
                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full no-icon-border"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                <vs-input
                  data-vv-validate-on="blur"
                  v-validate="'required|min:6'"
                  type="password"
                  name="password"
                  icon="icon icon-lock"
                  icon-pack="feather"
                  label-placeholder="Şifre"
                  v-model="password"
                  class="w-full mt-6 no-icon-border"
                  v-on:keyup.enter="login"
                />
                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                <!-- <vs-button  type="border" @click="registerUser">Register</vs-button> -->
                <vs-button class="float-right mb-2 mt-5" :disabled="!validateForm" @click="login">Giriş Yap</vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
      error: false
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != '';
    }
  },
  methods: {
    async login() {
      // Loading
      this.$vs.loading();
      const { token } = await AuthService.token();
      localStorage.setItem('appToken', token);

      try {
        const { access_token } = await AuthService.authorize({ email: this.email, password: this.password });
        localStorage.setItem('user', access_token);
        this.$store.dispatch('updateUserRole', 'admin');
        this.$vs.loading.close();
        this.$router.push(this.$route.query.redirect || '/dashboard/topic');
      } catch (error) {
        this.$vs.loading.close();
      }
    }
  }
};
</script>
<style>
#error {
  color: red;
}
</style>
